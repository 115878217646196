import React, { useRef } from 'react'
import GravityFormForm from 'gatsby-gravityforms-component'
import { useFormData } from '../hooks/useFormData'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const FormWrapper = styled.div`
  .gform_confirmation_wrapper {
    .gform_confirmation_message {
      color: ${props => props.textColor};
      font-family: ${props => props.theme.fonts.content};
      font-size: 18px;
      font-weight: normal;
    }
  }

  form {
    .validation_error { display: none; }

    .gform_body {
      .gform_fields {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        .gfield {
          position: relative;
          list-style-type: none;
          margin-bottom: 15px !important;
          margin-top: 0 !important;
          padding: 0;
          width: 100%;

          ${up('sm')} { margin-bottom: 30px !important; }

          label {
            font-family: ${props => props.theme.fonts.content};
            font-size: 16px;
            line-height: 19px;
            color: ${props => props.textColor};
            font-weight: normal;
            width: 100%;
          }

          .ginput_container {
            margin: 0;

            input,
            textarea {
              width: 100%;
              padding: 20px 0 !important;
              background-color: transparent;
              border: 0;
              border-radius: 0;
              border-bottom: 1px solid ${props => props.textColor};
              outline: none;
              font-family: ${props => props.theme.fonts.content} !important;
              font-size: 16px;
              line-height: 19px;
              color: ${props => props.textColor};

              &::placeholder {
                font-family: ${props => props.theme.fonts.content};
                font-size: 16px;
                line-height: 19px;
                color: ${props => props.textColor};
              }
            }
          }

          &.gravityform__field--error {
            border: 0 !important;
            background-color: transparent !important;

            .ginput_container {
              input,
              textarea {
                border: 1px solid ${props => props.errorColor} !important;
                padding: 20px 20px 20px 20px !important;
              }
            }

            .validation_message {
              font-family: ${props => props.theme.fonts.content};
              font-size: 12px;
              color: ${props => props.errorColor};
            }
          }

          &.half {
            width: 100%;

            &:nth-child(odd),
            &:nth-child(even) { padding: 0; }

            ${up('sm')} {
              width: 50%;

              &:nth-child(odd) { padding-right: 10px; }
              &:nth-child(even) { padding-left: 10px; }
            }
          }

          &.no-label {
            label { display: none; }
          }
        }
      }
    }

    .gform_footer {
      padding: 0;
      margin-top: 20px;

      .gform_button {
        font-family: ${props => props.theme.fonts.content};
        font-size: 16px;
        line-height: 19px;
        border-radius: 0;
        border: 1px solid ${props => props.textColor};
        background-color: white;
        color: ${props => props.textColor};
        cursor: pointer;
        padding: 15px;
        outline: none;
        appearance: none;
      }
    }
  }
`

const ErrorMessage = styled.div`
  color: ${props => props.errorColor};
  font-family: ${props => props.theme.fonts.content};
  font-size: 18px;
`

export default function Form({ id }) {
    const data = useFormData()
    const errorMessage = useRef(null)
    const formWrapper = useRef(null)

    const handleError = ({values, error, reset}) => {
      errorMessage.current.innerHTML = 'Une erreur s’est produite lors de votre envoi. Les erreurs ont été mises en surbrillance plus bas.'
      window.scroll.scrollTo(errorMessage.current, -100)
    }
    
    const handleSuccess = ({values, reset, confirmations}) => {
      window.scroll.scrollTo(formWrapper.current, -100)
      window.scroll.update()
    }

    return (
      <FormWrapper ref={formWrapper} textColor="black" errorColor="red">
        <ErrorMessage ref={errorMessage} errorColor="red"/>
        <GravityFormForm
          id={id}
          formData={data}
          lambda={process.env.GATSBY_LAMBDA_ENDPOINT}
          successCallback={handleSuccess}
          errorCallback={handleError}
        />
      </FormWrapper>
  )
}
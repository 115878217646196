import React, { useRef, useEffect } from 'react'
import SplitText from '../splitText'
import gsap from 'gsap'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 20px;
  font-family: ${props => props.theme.fonts.headings};
  font-size: 35px;
  color: ${props => props.theme.colors.orange};
`

const Text = styled.div`
  font-family: ${props => props.theme.fonts.content};
  font-size: 15px;
  line-height: 24px;
  color: #777777;
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 500px;
  overflow: hidden;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const ClassicContentWrapper = styled.section`
  margin-top: 25px;
  margin-bottom: 25px;
  display: inline-block;
  width: 100%;

  ${up('md')} {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`

const ClassicContent = (data) => {
  const splitTitle = useRef(null)
  const viewEvent = Math.random().toString(36).substr(2, 9)

  const onEnterView = () => {
    const titles = splitTitle.current.getSplittedText().words

    gsap.to(titles, {
      delay: 0.2,
      stagger: 0.1,
      duration: 0.6,
      y: 0,
      rotate: 0,
      alpha: 1,
      ease: 'power2.out'
    })
  }

  useEffect(() => {
    gsap.set(splitTitle.current.getSplittedText().words, { y: 30, alpha: 0, rotate: '8deg', display: 'inline-block' })
    window.addEventListener(viewEvent, onEnterView)

    return () => {
      window.removeEventListener(viewEvent, onEnterView)
    }
  })

  return (
    <ClassicContentWrapper data-scroll data-scroll-call={viewEvent}>
      <Container>
        <Row>
          <Col md={5}>
            <ImageWrapper>
              <Img fluid={ data.image.sourceUrlSharp.childImageSharp.fluid } alt={ data.image.altText } />
            </ImageWrapper>
          </Col>
          <Col md={6} offset={{ md: 1}}>
            <SplitText ref={splitTitle}>
              <Title>{data.title}</Title>
            </SplitText>
            <Text dangerouslySetInnerHTML={{__html: data.content}}/>
          </Col>
        </Row>
      </Container>
    </ClassicContentWrapper>
  )
}

export default ClassicContent
import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Title = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0;
  margin-bottom: 20px;
  font-family: ${props => props.theme.fonts.headings};
  font-size: 68px;
  text-transform: uppercase;
  color: white;
`

const CoverImage = styled.div`
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const Section = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  margin-bottom: 50px;
  overflow: hidden;
  text-align: center;
`

const Cover = (data) => (
  <Section>
    <CoverImage data-scroll data-scroll-speed="-1">
      <Img fluid={ data.image.sourceUrlSharp.childImageSharp.fluid } alt={ data.image.altText } />
    </CoverImage>
    <Title>{data.title}</Title>
  </Section>
)

export default Cover
import React from 'react'
import Form from '../form'
import { Container, Row, Col } from 'react-grid-system'

const Contact = (data) => (
  <Container>
    <Row>
      <Col sm={12}>
        <div>{data.title}</div>
        <Form id={parseInt(data.formId)}></Form>
      </Col>
    </Row>
  </Container>
)

export default Contact

import React from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const CoreColumnsWrapper = styled.div`
  .wp-block-columns {
    display: flex;
    flex-direction: column;

    ${up('sm')} { flex-direction: row; }

    .wp-block-column {
      display: flex;
      flex-basis: 50%;

      &:first-child { 
        margin-bottom: 20px; 

        figure { margin-left: 0; }
      }
      
      &:last-child {
        figure { margin-right: 0; }
      }

      &.is-vertically-aligned-top { justify-content: flex-start; }
      &.is-vertically-aligned-center { justify-content: center; }
      &.is-vertically-aligned-bottom { justify-content: flex-end; }

      ${up('sm')} {
        &:first-child { margin-bottom: 0; }
      }  

      figure {
        position: relative;
        padding-top: 56.25%;
        width: 100%;
        height: 0;

        ${up('sm')} { 
          height: 100%;
          padding-top: 0;
        }

        &.alignleft {
          float: left;
          margin-right: 0;
          margin-top: 0;
          margin-bottom: 0;

          ${up('sm')} { margin-right: 20px; }
        }

        &.alignright {
          float: right;
          margin-left: 0;
          margin-top: 0;
          margin-bottom: 0;

          ${up('sm')} { margin-left: 20px; }
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;

          ${up('sm')} {
            position: relative;
          }
        }
      }
    }
  }
`

const CoreColumns = (data) => {

  console.log(data)

  return (
    <CoreColumnsWrapper>
      <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
    </CoreColumnsWrapper>
  )
}

export default CoreColumns